import React, { useState } from 'react';

// libs
import { useNavigate } from "react-router-dom";

// components
import { VideoModal } from "../../modals";
import { Benefits, Countdown, Layout } from "../../common";
import { routes } from "../../../utils/routes";

// assets
import styles from './Kayoralpha.module.scss';
import playBtn from "../../../images/icons/play.svg";
import background from "../../../images/mat.joe-banner.jpg";
import user from "../../../images/icons/user.svg";
import mp3 from "../../../images/icons/mp3.svg";
import money from "../../../images/icons/money.svg";
import ticket from "../../../images/icons/ticket.svg";

export const Kayoralpha = () => {
  const navigate = useNavigate();

  const benefits = [
  ]
  
  if (process.env.REACT_APP_PERK_TICKET) {
    benefits.push({ title: process.env.REACT_APP_PERK_TICKET, icon: ticket })
  }
  
  if (process.env.REACT_APP_PERK_NFT_IMAGE) {
    benefits.push({ title: 'A unique numbered exclusive image just for you', icon: user })
  }
  
  if (process.env.REACT_APP_PERK_DOWNLOAD) {
    benefits.push({ title: 'A MP3 download of the song to keep', icon: mp3 })
  }
  
  if (process.env.REACT_APP_PERK_ROYALTY) {
    benefits.push({ title: 'A share of the streaming revenues from ‘' + process.env.REACT_APP_SONG_NAME +'’ forever!', icon: money })
  }
  const [showVideo, setShowVideo] = useState(false);

  const artist_name = process.env.REACT_APP_ARTIST_NAME.toUpperCase()
  const song_name = process.env.REACT_APP_SONG_NAME

  const title = process.env.REACT_APP_SONG_NAME + ' - ' + process.env.REACT_APP_ARTIST_NAME + ' on SongBits'
  const video_url = process.env.REACT_APP_VIDEO_URL

  
  const str_date = process.env.REACT_APP_RELEASE_AT 

  const year = parseInt(str_date.substring(0, 4));
  const month = parseInt(str_date.substring(5, 7)) - 1;
  const day = parseInt(str_date.substring(8, 10));
  
  const hour = parseInt(str_date.substring(11, 13));
  const minute = parseInt(str_date.substring(14, 16));
  const second = parseInt(str_date.substring(17, 19));

  const release_date = new Date(Date.UTC(year, month, day, hour, minute, second))
  const release_date_epoch = release_date.getTime()

  function dateOrdinal(dom) {
    if (dom === 31 || dom === 21 || dom === 1) {
      return dom + "st";
    }else if (dom === 22 || dom === 2) {
      return dom + "nd";
    } else if (dom === 23 || dom === 3) {
      return dom + "rd";
    } else {
      return dom + "th";
    } 
  };

  function formatAMPM(date) {
    var hours = date.getUTCHours();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    
    var strTime = hours + ampm;

    return strTime;
  }

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
  var release_date_string = ' ' + dateOrdinal(release_date.getUTCDate()) + ' ' + monthNames[release_date.getUTCMonth()] + ' ' + release_date.getUTCFullYear() + ' ' + formatAMPM(release_date)

  return (
    <Layout title={title} artistName={process.env.REACT_APP_ARTIST_NAME} songName={process.env.REACT_APP_SONG_NAME} background={background} noLogo>
      {showVideo && <VideoModal setShowVideo={setShowVideo} src={video_url} />}
      <div className={styles.kayoralpha}>
        <div className={styles.title}>
          <div className={styles.black}>
            <p>ownership release</p>
          </div>
          <h1>{artist_name}</h1>
          <p className={styles.drop}>‘{song_name}’</p>
        </div>
        <div className={styles.content}>
          <div className={styles.left}>
            <p>
              Grab your chance to share in the success of {artist_name}'s latest
              song <span className={styles.bold}>‘{song_name}’</span> exclusively for fans releasing
              {release_date_string} (GMT)...
            </p>
            <p>
              <span>How?</span> Join the waitlist below to receive your invite to buy a bit of the song and receive a
              share of the song’s streaming royalties for life plus exclusive VIP benefits!
            </p>
            <p>
              Join the waitlist now below...
            </p>
            <button
              className={"btn btn-yellow"}
              onClick={() => navigate(routes.joinWaitList)}>
              join the <span>waitlist</span>
            </button>
            <p>
              <span>Who said VIP?</span> All purchases come with a package of VIP benefits including
            </p>
            <Benefits benefits={benefits} />
          </div>
          <div className={styles.right}>
            {video_url ? <><div className={styles.play} onClick={() => setShowVideo(true)}>
              <img src={playBtn} alt="" />
            </div></> : <></>}
            <p className={styles.yellow}>COUNTDOWN TO SALE </p>
            <div className={styles.timer}>
              <Countdown time={release_date_epoch} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}